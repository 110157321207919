// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../../assets/stylesheets/application";
import "../../assets/images/favicon.png";

import "@fortawesome/fontawesome-free/css/all.css";
import "@activeadmin/activeadmin";
import "activeadmin_addons";
import "arctic_admin";

require("trix");
require("@rails/actiontext");
